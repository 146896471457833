import { AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject, map, Observable, Subject, takeUntil } from 'rxjs';
import { AppService, ButtonStatus } from '../../app.service';
import { ContractService } from '../../contract/contract.service';
import { ContractParsed } from '../../models/contract';
import { AppUser } from '../../models/db-user';
import { Department } from '../../models/department';
import { DayOptions, HandleTimesheetsParams, ListTimesheetsParams, Timesheet, TimesheetParsed, TimesheetStatus } from '../../models/timesheet';
import { UserService } from '../../user.service';
import { EditableFieldOptions, EditableFieldType, FieldValue } from '../../util/editable-value/editable-value.component';
import { NotificationService } from '../../util/notifications/notification.service';
import { TimesheetService } from '../timesheet.service';
import { CompositeFilterDescriptor, GroupDescriptor } from '@progress/kendo-data-query';
import { StateService, getReorderedConfig, getResizedConfig } from '../../state.service';
import { ColumnReorderEvent, ColumnResizeArgs, DataStateChangeEvent, GridComponent, GroupKey, GroupRowArgs } from '@progress/kendo-angular-grid';
import { T7eService } from '../../t7e/t7e.service';
import { ShootingLocation } from '../../models/location';
import { ProductionService } from '../../prod/production.service';
import { config } from '../../config';
import { SQL_DATE_FORMAT, SQL_TIME_FORMAT } from '../../parser.service';

@Component({
  selector: 'app-timesheets',
  templateUrl: './timesheets.component.html',
  styleUrls: ['./timesheets.component.scss']
})
export class TimesheetsComponent implements OnInit, OnDestroy, AfterViewInit {
  MEAL_PENALTY = config.HU.TS.MEAL_PENALTY

  @Input() tsList$?: BehaviorSubject<Timesheet[]>
  @Input() tsListLoading: boolean = false
  @Input() groupable: boolean = true
  @Input() showUserName: boolean = true
  @Input() useSavedFilters: boolean = true

  /**
   * Ha meg van adva, akkor teljes magasságú lesz a grid, és a .next() hívásra újraszámolja a magasságot
   */
  @Input() sizeChanged$?: Observable<boolean>
  @ViewChild('grid') grid!: GridComponent
  @HostListener('window:resize', ['$event'])
  onWindowResize?: (event: Event) => void = () => { }

  _destroy$ = new Subject<void>()
  get debug() { return this.appSvc.debug }
  getStatusName = this.tsSvc.getStatusName
  getStatusClass = this.tsSvc.getStatusClass
  getRowClass = this.tsSvc.getRowClass
  getMealPenaltyFee_FirstValuableOtRate = this.tsSvc.getMealPenaltyFee_FirstValuableOtRate
  getMealPenalty_setAtProdDeptSf = this.tsSvc.getMealPenaltyRate_setAtProdDeptSf
  iskmParkVignSuspicious = this.tsSvc.iskmParkVignSuspicious
  dateFormat = 'yyyy. MMM. dd.'
  timeFormat = SQL_TIME_FORMAT

  users$ = new BehaviorSubject<AppUser[]>([])
  depts$ = new BehaviorSubject<Department[]>([])

  isDataLoaded = false
  showSaveComment: { ts: Timesheet, title: string, saveButtonText: string, isCommentOptional: boolean, newStatus: TimesheetStatus } | null = null

  /** usid, user's numPlannedDays */
  numPlannedDays = new Map()

  selectedSalaryReason?: {
    tsid: number,
    showSalary: boolean,
    showOt: boolean,
    showTa: boolean,
  }
  get isLargeScreen() { return this.appSvc.isLargeScreen }

  constructor(
    private elementRef: ElementRef,
    private tsSvc: TimesheetService,
    private contractSvc: ContractService,
    private userSvc: UserService,
    private appSvc: AppService,
    private prodSvc: ProductionService,
    private notifSvc: NotificationService,
    private stateSvc: StateService,
    private t7e: T7eService,
  ) {
    this.contractSvc.allContracts$
      .pipe(takeUntil(this._destroy$))
      .subscribe(contracts => {
      this.numPlannedDays.clear()
      contracts?.forEach(c => {
        const userTotal = this.numPlannedDays.get(c.usid) || 0
        this.numPlannedDays.set(c.usid, userTotal + c.f_numPlannedDays || 0)
      })
    })
  }

  ngOnInit(): void {
    if (!this.groupable) this.group = []
    this.tsList$ 
      ?.pipe(takeUntil(this._destroy$))
      .subscribe(data => {
      if (!data) data = []
      console.log('number of ts: ' + data.length)
      this.isDataLoaded = true
      let i = 0;
      while (i < this.selectedTsids.length) {
        if (!data.find(ts => ts.tsid === this.selectedTsids[i])) this.selectedTsids.splice(i, 1)
        else i++
      }

      if (data.length < this.skip) this.resetSkip()
    })
    if (this.sizeChanged$) {
      this.sizeChanged$
        .pipe(takeUntil(this._destroy$))
        .subscribe(() => this.setGidHeight())
      this.onWindowResize = (event: Event) => this.setGidHeight()
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.complete()
  }

  ngAfterViewInit(): void {

    setTimeout(() => {
      // csak akkor kell full-screenre, ha van sizeChanged$
      this.sizeChanged$ && this.setGidHeight() // ez azért kell, mert a Subject.subscribe() az előtt fut le, hogy kész lenne a renderelés
      return
      this.grid.columns
        .map((col, i) => {
          col.orderIndex = col.orderIndex || i
          return col
        })
        .sort((a, b) => a.orderIndex - b.orderIndex)
        .forEach(col => {
          //@ts-ignore
          const newIndex = this.gridSettings?.columnsConfig?.[col.field]?.orderIndex
          if (newIndex === undefined) return
          this.grid.reorderColumn(col, newIndex, { before: true })
        })
    }, 1) 
  }

  removeTs(ts: Timesheet): void {
    this.showSaveComment = {
      ts,
      isCommentOptional: true,
      title: this.t7eConfirmDelete,
      newStatus: TimesheetStatus.deleted,
      saveButtonText: this.t7ebtnDelete,
    }
  }

  approve(ts: Timesheet): void {
    this.setStatus(ts, TimesheetStatus.approved)
  }

  sendBackToCrew(ts: Timesheet): void {
    this.showSaveComment = {
      ts,
      isCommentOptional: false,
      title: this.t7eConfirmSendBack,
      newStatus: TimesheetStatus.atCrewMember,
      saveButtonText: this.t7ebtnSendBack,
    }
  }

  sendToProdMgr(ts: Timesheet): void {
    this.setStatus(ts, TimesheetStatus.atProdMgr)
  }
  sendBackToProdMgr(ts: Timesheet): void {
    this.setStatus(ts, TimesheetStatus.atProdMgr)
  }

  setPdfSent(ts: Timesheet): void {
    this.setStatus(ts, TimesheetStatus.attSent)
  }

  setInvoiceAccepted(ts: Timesheet): void {
    this.setStatus(ts, TimesheetStatus.invAccepted)
  }

  editableFields: { tsid: number, param: string, fieldOptions: EditableFieldOptions }[] = []
  saveField(savedValue: FieldValue, ts: TimesheetParsed, paramName: string): void {
    const editableField: EditableFieldOptions = this.getEditable(ts, paramName)
    const params: HandleTimesheetsParams = {
      _tsid: ts.tsid,
    }
    if (paramName.includes('*')) paramName = paramName.split('*')[0] // *2 van a param végére téve, ha ugyanilyen param már létezik, de más típusú (startdate-nél)
    params['_' + paramName] = savedValue
    this.tsSvc.handleTs(params)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
      next: savedTSs => {
        const savedTs = savedTSs?.[0]
        if (!savedTs) {
          editableField.isSuccess.next(false)
          editableField.isError.next(this.t7eNotifErrorNoResponseData)
          return
        }
        editableField.value$?.next(savedTs[paramName])
        editableField.isSuccess.next(true)
        editableField.isError.next(null)
      },
      error: err => {
        editableField.isSuccess.next(false)
        editableField.isError.next(err)
      }
    })
  }

  getEditable(ts: TimesheetParsed, param: string, type: EditableFieldType = 'text'): EditableFieldOptions {
    let retVal: EditableFieldOptions | null = this.editableFields
      .find(f => (f.tsid === ts.tsid && f.param === param))?.fieldOptions || null
    if (!retVal) {
      const paramTrimmed = param.split('*')[0] // *2 van a param végére téve, ha ugyanilyen param már létezik, de más típusú (startdate-nél)
      retVal = {
        type,
        disabled: this.canEdit.disabled,
        valueTitle: this.canEdit.title,
        value$: new BehaviorSubject<FieldValue>(ts[paramTrimmed]),
        calcValue$: new BehaviorSubject<FieldValue>(ts['calc_' + paramTrimmed]),
        numberOptions: { min: 0, step: 10000 },
        isError: new BehaviorSubject<string | null>(null),
        isSuccess: new BehaviorSubject<boolean | null>(null),
        hint: this.t7eOverrideDailyRateHint,
      }
      this.editableFields.push({ tsid: ts.tsid as number, param: param, fieldOptions: retVal })
    }
    return retVal
  }

  private setStatus(ts: Timesheet, status: number, savecomment: string | null = null): void {
    const params = this.tsSvc.getSaveNewStatusParams(ts, status, savecomment)
    this.tsSvc.handleTs(params)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: data => {
          if (!data) return
          const orig = [...this.tsList$?.value!]
          this.tsList$?.next(this.tsSvc.replaceTsItems(data, orig))
        },
        error: err => {
          this.notifSvc.addObservableNotif({ msg: this.t7eNotifSaveError, duration: 0, class: 'danger'})
        }
      })
  }
  
  sendToProdMgrSelected(): void {
    const aTs = this.selectedTsids.map(tsid => this.tsList$?.value.find(t => t.tsid === tsid))
    const aReqParams = aTs.map(ts => this.tsSvc.getSaveNewStatusParams(ts!, TimesheetStatus.atProdMgr, null)).filter(ts => !!ts)
    const hNotifSave = this.notifSvc.addObservableNotif({ msg: this.t7eNotifSubmitting, duration: false, class: 'info' })
    this.tsSvc.handleMultipleTs(aReqParams)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: data => {
          this.notifSvc.modifyNotif(hNotifSave, { msg: this.t7eNotifSubmitSuccess, duration: 3000, class: 'success' })
          if (!data) return
          const orig = [...this.tsList$?.value!]
          this.tsList$?.next(this.tsSvc.replaceTsItems(data, orig))
        },
        error: err => {
          this.notifSvc.modifyNotif(hNotifSave, { msg: this.t7eNotifSubmitError, duration: 0, class: 'danger' })
        }
      })

  }
  approveSelected(): void {
    const aTs = this.selectedTsids.map(tsid => this.tsList$?.value.find(t => t.tsid === tsid))
    const aReqParams = aTs.map(ts => this.tsSvc.getSaveNewStatusParams(ts!, TimesheetStatus.approved, null)).filter(ts => !!ts)
    const hNotifSave = this.notifSvc.addObservableNotif({ msg: this.t7eNotifApproving, duration: false, class: 'info'})
    this.tsSvc.handleMultipleTs(aReqParams)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: data => {
          this.notifSvc.modifyNotif(hNotifSave, { msg: this.t7eNotifApproveSuccess, duration: 3000, class: 'success'})
          if (!data) return
          const orig = [...this.tsList$?.value!]
          this.tsList$?.next(this.tsSvc.replaceTsItems(data, orig))
        },
        error: err => {
          this.notifSvc.modifyNotif(hNotifSave, { msg: this.t7eNotifApproveError, duration: 0, class: 'danger'})
        }
      })
  }

  canApprove=this.tsSvc.canApprove
  canSendBackToCrew=this.tsSvc.canSendBackToCrew
  canRetractApproval = this.tsSvc.canRetractApproval
  canSetPdfSent=this.tsSvc.canSetPdfSent
  canSetInvoiceAccepted=this.tsSvc.canSetInvoiceAccepted
  canRemoveTs = this.tsSvc.canRemoveTs
  isDisabled = this.tsSvc.isDisabled
  isCrew = this.userSvc.isCrew
  isProdMgr = this.userSvc.isProdMgr
  isFinance = this.userSvc.isFinance
  isDev = this.userSvc.isDev
  
  get canEdit(): ButtonStatus {
    return { disabled: !this.isProdMgr, title: this.isProdMgr ? '' : this.t7eYouAreNotProdMgr }
  }


  saveCommentReceived(comment: string): void {
    console.log(comment)
    this.setStatus(this.showSaveComment?.ts!, this.showSaveComment?.newStatus!, comment)
    this.showSaveComment = null
  }

  getExtraFeesSum(dataItem: TimesheetParsed): number {
    return (dataItem.calc_overtimefee || 0)
      + (dataItem.calc_tafee || 0)
      + (dataItem.f_mealPenalty || 0)
      + (dataItem.distandpark || 0)
      + (dataItem.park || 0) 
      + (dataItem.vignette || 0) 
      + (dataItem.otherfee || 0) 
      + (dataItem.f_dailybasedrentalrate1 || 0) 
      + (dataItem.f_dailybasedrentalrate2 || 0) 
      + (dataItem.f_dailybasedrentalrate3 || 0)
      + (dataItem.f_dailybasedrentalrate4 || 0)
      + (dataItem.f_dailybasedrentalrate5 || 0)
      + (dataItem.f_dailybasedrentalrate6 || 0)
  }

  getRentalNames(ts?: Timesheet){//: { f_dailybasedrentalrate1?: string, f_dailybasedrentalrate2?: string, ... } {
    const c = this.contractSvc.getContractById(ts?.conid)
    if (!c) return {}
    return {
      f_dailybasedrentalrate1: c?.f_dailybasedrentalrate1name || undefined,
      f_dailybasedrentalrate2: c?.f_dailybasedrentalrate2name || undefined,
      f_dailybasedrentalrate3: c?.f_dailybasedrentalrate3name || undefined,
      f_dailybasedrentalrate4: c?.f_dailybasedrentalrate4name || undefined,
      f_dailybasedrentalrate5: c?.f_dailybasedrentalrate5name || undefined,
      f_dailybasedrentalrate6: c?.f_dailybasedrentalrate6name || undefined,
    } as any
  }
  trackByIndex = this.appSvc.trackByIndex

  getDayoptionsName(dayoptions: DayOptions[]): string[] {
    const retVal: string[] = []
    if (dayoptions.includes(DayOptions.PerDiem)) retVal.push('Per Diem')
    if (dayoptions.includes(DayOptions.TravelDay)) retVal.push(this.t7eTravelDay)
    if (dayoptions.includes(DayOptions.HalfDay)) retVal.push(this.t7eHalfDay)
    if (dayoptions.includes(DayOptions.MonthlyRentsDue)) retVal.push(this.t7eMonthlyRentsDue)
    if (dayoptions.includes(DayOptions.MealPenalty)) retVal.push('Meal Penalty')
    return retVal
  }

  setForReasons(tsid?: number | null, show?: 'salary' | 'ot' | 'ta' | 'all'): void {
    if (!show) show = 'all'

    this.selectedSalaryReason = !tsid ? undefined : {
      tsid : tsid,
      showSalary: show === 'salary' || show === 'all',
      showOt: show === 'ot' || show === 'all',
      showTa: show === 'ta' || show === 'all',
    }
  }

  getLocation(locId?: number | string | null): ShootingLocation | undefined {
    return this.prodSvc.locations$.value?.find(loc => loc.locId === locId || loc.locCode === locId)
  }

  private setGidHeight(): void {
    const gridElement = this.elementRef.nativeElement.querySelector('.grid');
    const topOffset = gridElement.getBoundingClientRect().top
    const windowHeight = window.innerHeight;
    const gridHeight = windowHeight - topOffset;
    gridElement.style.height = gridHeight + 'px';
  }

  /** USER PREFERENCES */

  get selectedTsids() { return this.gridSettings?.selectedTimesheetIds || [] }
  set selectedTsids(val: number[]) {
    this.stateSvc.setManageTimesheetsPageState({ grid: { selectedTimesheetIds: val, } })
  }

  get gridSettings() {
    return this.useSavedFilters
      ? this.stateSvc.manageTimesheetsPageState$.value?.grid
      : {
        expandedDetailKeys: this.stateSvc.manageTimesheetsPageState$.value?.grid?.expandedDetailKeys,
        selectedTimesheetIds: this.stateSvc.manageTimesheetsPageState$.value?.grid?.selectedTimesheetIds,
      }
  }
  get skip() { return this.gridSettings?.state?.skip || 0 }
  get sort() { return this.gridSettings?.state?.sort || [] }
  get filter() { return this.gridSettings?.state?.filter || { filters: [], logic: 'and' } }
  get group() {
    if (!this.useSavedFilters) return []
    return this.gridSettings?.state?.group || [{ field: 'depname', }, { field: 'usname', }]
  }
  set group(val: GroupDescriptor[]) {
    this.stateSvc.setManageTimesheetsPageState({ grid: { state: { group: val } } })
  }
  get expandedGroupKeys() { return this.gridSettings?.expandedGroupKeys || [] }
  set expandedGroupKeys(val: Array<GroupKey>) {
    this.stateSvc.setManageTimesheetsPageState({ grid: { expandedGroupKeys: val } })
  }

  get expandedDetailKeys() { return this.gridSettings?.expandedDetailKeys || [] }
  set expandedDetailKeys(val: number[]) {
    this.stateSvc.setManageTimesheetsPageState({ grid: { expandedDetailKeys: val, } })
  }

  dataStateChange(state: DataStateChangeEvent): void {
    if (!this.useSavedFilters) return
    this.stateSvc.setManageTimesheetsPageState({ grid: { state } })
  }

  filterChanged(event: CompositeFilterDescriptor): void {
    this.resetSkip()
  }
  resetSkip(): void {
    this.stateSvc.setManageTimesheetsPageState({
      grid: { state: { skip: 0 } }
    })
  }

  public onColReorder(e: ColumnReorderEvent): void {
    // set the new orderIndex to all affected columns
    const newColumnsConfig: any = getReorderedConfig(this.grid, e)
    this.stateSvc.setManageTimesheetsPageState({ grid: { columnsConfig: newColumnsConfig } })
    //console.log(this.stateSvc.manageTimesheetsPageState$.value?.grid?.columnsConfig)
  }

  public onColResized(e: ColumnResizeArgs[]): void {
    const newConfigs = getResizedConfig(e)
    newConfigs.forEach((newConfigs) => {
      this.stateSvc.setManageTimesheetsPageState({ grid: { columnsConfig: newConfigs } });
    })
  }

  public groupChange(): void {
    this.expandedGroupKeys = [];
  }
  getWidth(field: string): number | undefined {
    const columnsConfig = this.gridSettings?.columnsConfig || {}
    return columnsConfig[field]?.width
  }

  /** T7E */
  readonly compName = 'app-timesheets'
  t7eNoRecord = this.t7e.getTranslation(this.compName, 'no-record', 'innerText', null, null, 'Nincs a szűrésnek megfelelő Timesheet')
  t7eNotLoaded = this.t7e.getTranslation(this.compName, 'not-loaded', 'innerText', null, null, 'Válassz szűrőket és kattints a Betöltés gombra')
  t7eConfirmDelete = this.t7e.getTranslation(this.compName, 'confirm', 'delete-title', null, null, 'Biztos törlöd?')
  t7ebtnDelete = this.t7e.getTranslation(this.compName, 'confirm', 'btn-delete', null, null, 'Törlés')
  t7eConfirmSendBack = this.t7e.getTranslation(this.compName, 'confirm', 'send-back-title', null, null, 'Valóban visszaküldöd? Miért?')
  t7ebtnSendBack = this.t7e.getTranslation(this.compName, 'confirm', 'btn-send-back', null, null, 'Visszaküldés')
  t7eOverrideDailyRateHint = this.t7e.getTranslation(this.compName, 'override-daily-rate-hint', 'innerText', null, null, 'Felülírhatod a kalkulált napidíjat')
  
  t7eNotifErrorNoResponseData = this.t7e.getTranslation(this.compName, 'notif', 'error-no-response-data', null, null, 'A mentés nem adott vissza eredményt')
  t7eNotifSaveError = this.t7e.getTranslation(this.compName, 'notif', 'save-error', null, null, 'Hiba történt a mentés közben')
  t7eNotifSubmitting = this.t7e.getTranslation(this.compName, 'notif', 'sending', null, null, 'Beküldés folyamatban...')
  t7eNotifSubmitSuccess = this.t7e.getTranslation(this.compName, 'notif', 'submit-success', null, null, 'Sikeres beküldés')
  t7eNotifSubmitError = this.t7e.getTranslation(this.compName, 'notif', 'submit-error', null, null, 'Hiba történt a beküldés közben')
  t7eNotifApproving = this.t7e.getTranslation(this.compName, 'notif', 'approving', null, null, 'Jóváhagyás folyamatban...')
  t7eNotifApproveSuccess = this.t7e.getTranslation(this.compName, 'notif', 'approve-success', null, null, 'Sikeres jóváhagyás')
  t7eNotifApproveError = this.t7e.getTranslation(this.compName, 'notif', 'approve-error', null, null, 'Hiba történt a jóváhagyás közben')

  t7eYouAreNotProdMgr = this.t7e.getTranslation(this.compName, 'you-are-not-prod-mgr', 'innerText', null, null, 'Nem vagy gyártásvezető')
  
  t7eTravelDay = this.t7e.getTranslation(this.compName, 'dayoptions', 'travel-day', null, null, 'Utazó nap')
  t7eHalfDay = this.t7e.getTranslation(this.compName, 'dayoptions', 'half-day', null, null, 'Fél nap')
  t7eMonthlyRentsDue = this.t7e.getTranslation(this.compName, 'dayoptions', 'monthly-rents-due', null, null, 'Havi bérleti díj esedékes')

}
