export const dev = {
    auth0: {
        domain: 'dev-crew-time.eu.auth0.com',
        clientId: '8BO7du2Rojvrryziw0xdhCNNENbjEVPs',
        audience: 'https://crew-time/api',
    }
}

export const prod = {
    auth0: {
        domain: 'dev-crew-time.eu.auth0.com',
        clientId: '8BO7du2Rojvrryziw0xdhCNNENbjEVPs',
        audience: 'https://crew-time/api',
    }
}